import CheckCircleIcon from "@lagora/assets/icons/check-circle.svg?react";
import ExclamationTriangleIcon from "@lagora/assets/icons/exclamation-triangle.svg?react";
import InformationCircleIcon from "@lagora/assets/icons/information-circle.svg?react";
import XCircleIcon from "@lagora/assets/icons/x-circle.svg?react";
import type React from "react";
import type { FunctionComponent } from "react";
import type { Notification } from "../types";

type Props = {
  "data-testid"?: string;
  notification: Notification;
};

const iconsMap: Record<
  Notification["type"],
  FunctionComponent<
    {
      title?: string;
    } & React.SVGProps<SVGSVGElement>
  >
> = {
  error: XCircleIcon,
  info: InformationCircleIcon,
  success: CheckCircleIcon,
  warning: ExclamationTriangleIcon,
};

export default function Alert(props: Props) {
  const { notification } = props;

  const Icon = iconsMap[notification.type];

  return (
    <div
      className={`alert alert-${notification.type} grid-cols-12 grid-flow-row-dense`}
      data-testid={props["data-testid"]}
      key={notification.id}
    >
      <Icon className="col-span-2 w-8" strokeWidth="2.5" />
      <span className="col-span-10 font-bold text-wrap text-start">
        {notification.message}
      </span>
    </div>
  );
}
